// import Swiper JS
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const wrapperSwiper = document.querySelector('.hero-block');

    if (wrapperSwiper) {
        const swiperElement = wrapperSwiper.querySelector('.swiper');
        const buttonPrev = wrapperSwiper.querySelector('.swiper-button-prev');
        const buttonNext = wrapperSwiper.querySelector('.swiper-button-next');
        const current = wrapperSwiper.querySelector('.current');
    
        const slider = new Swiper(swiperElement, {
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            // observer: true,
            // observeParents: true,
            // centerInsufficientSlides: true,
            // autoHeight: true,
            // centeredSlides: true,
            on: {
                slideChange: (swiper)=>{
                    let number = swiper.realIndex + 1;
                    if (number < 10) {
                        number = "0" + number;
                    }
                    current.innerHTML = number;
                },
            },
    
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },
        });

        slider;    
    }
}
